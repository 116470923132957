import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthBO from '../businessObject/AuthBO';
import logo from '../../images/dl-logo.png';
import SuccessConfirmation from '../../common/components/successConfirmation/SuccessConfirmation';
import FailConfirmation from '../../common/components/failConfirmation/FailConfirmation';
// import imgLogin from '../../images/dl-login.png';
import imgLogin from '../../images/new_login_screen_image.png';
import useResetPassword from './useResetPassword';

export default function ResetPassword(props) {
    const { user } = props;
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [failMessage, setFailMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showCpassword, setShowCpassword] = useState(false);
    const { executeReset } = useResetPassword();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sendToHome = () => navigate('/', { replace: true });
    const sendToLogin = () => navigate('/sign-in', { replace: true });

    if (user) {
        sendToHome();
    }

    const identifier = searchParams.get('id');

    const _onExecuteNewPassword = async () => {
        try {
            if (AuthBO.validateNewPassword(password, confirmPassword)) {
                const checkResult = await executeReset(identifier, password);
                if (checkResult === true) {
                    setSuccessMessage('Proceso realizado con éxito. Redirigiendo a login.');
                    setTimeout(sendToLogin, 3000);
                } else {
                    setFailMessage(
                        'No se pudo realizar el proceso de cambio de contraseña. Inténtelo de nuevo o contacte a soporte.'
                    );
                    setTimeout(() => setFailMessage(null), 5000);
                }
            }
        } catch (e) {
            setFailMessage('Ocurrió un error. Inténtelo de nuevo.');
            setTimeout(() => setFailMessage(null), 5000);
        }
    };

    return (
        <>
            <div className="h-[calc(100vh_-_306px)] flex bg-dl-screen-gray">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Link to={'/'}>
                                <img className="w-72 mx-auto" src={logo} alt="DoctoLink" />
                            </Link>
                            <h2 className="mt-6 text-3xl font-extrabold text-dl-secondary">
                                Ingrese una nueva contraseña
                            </h2>
                            <p className="mt-2 text-sm text-dl-secondary">
                                <span className="font-medium text-dl-secondary">
                                    Escribe tu nueva contraseña y confirmala.
                                </span>
                            </p>
                            <div className="h-5 my-4 contents">
                                {Boolean(successMessage) && (
                                    <SuccessConfirmation
                                        message={successMessage}
                                        onClose={() => setSuccessMessage(null)}
                                    />
                                )}
                                {Boolean(failMessage) && (
                                    <FailConfirmation errorMessage={failMessage} onClose={() => setFailMessage(null)} />
                                )}
                            </div>
                        </div>

                        <div className="mt-10">
                            <div className="mt-6">
                                <form action="#" method="POST" className="space-y-6">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-dl-accent">
                                            Contraseña
                                        </label>
                                        <div className="relative mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type={showPassword ? "text" : "password"}
                                                value={password}
                                                onInput={(event) => setPassword(event.target.value)}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                                            />
                                            <span className='absolute top-2 right-3'>
                                                {showPassword
                                                    ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => setShowPassword(false)} >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>

                                                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => setShowPassword(true)}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-dl-accent">
                                            Confirmar Contraseña
                                        </label>
                                        <div className="relative mt-1">
                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type={showCpassword ? "text" : "password"}
                                                value={confirmPassword}
                                                onInput={(event) => setConfirmPassword(event.target.value)}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                                            />
                                            <span className='absolute top-2 right-3'>
                                                {showCpassword
                                                    ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => setShowCpassword(false)} >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>

                                                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer" onClick={() => setShowCpassword(true)}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="button"
                                            disabled={
                                                !password ||
                                                !confirmPassword ||
                                                !AuthBO.validateNewPassword(password, confirmPassword)
                                            }
                                            className="disabled:opacity-50 disabled:bg-dl-primary-500 w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                                            onClick={_onExecuteNewPassword}
                                        >
                                            Crear nueva contraseña
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1">
                    <img className="absolute inset-0 h-full w-full object-cover" src={imgLogin} alt="" />
                </div>
            </div>
        </>
    );
}
