import { useEffect, useState } from 'react';
import fetchDoctorsSearch from '../api/searchDoctors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { buildSearch } from '../../common/utils/url';
import getCity from '../../api/getCity';
import getSpecialities from '../../api/getSpecialties';
import useSearch from '../../home/hooks/useSearch';
import fetchAuthenticatedUser from '../../api/fetchAuthenticatedUser';
import { format } from 'date-fns';

const useSearchResults = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [relatedSpecialities, setRelatedSpecialities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchParams] = useSearchParams();
  const [citiesFilter, setCitiesFilter] = useState([]);
  const [specialitiesFilter, setSpecialitiesFilter] = useState([]);

  const buildSearchParams = () => {
    let date;
    const { date: timeDate, city, q } = getCurrentSearchParams();

    return buildSearch({ date:  timeDate, city, q });
  };

  const getCurrentSearchParams = () => {
      
      const date = searchParams.get('date');
      const city = searchParams.get('city');
      const q = searchParams.get('q');
    return { date, city, q };
  };

  const research = async (newSearchParams) => {
    const currentSearchParams = getCurrentSearchParams();
      navigate({
        pathname: '/search-results',
        search: buildSearch({ ...currentSearchParams, ...newSearchParams })
      });
  };


  const researchDoc = async (person) => {
    const user = await fetchAuthenticatedUser();
    if (user.id){

        navigate({
            pathname: `/doctor-results/${person.id}/${person.fullName.replace(' ', '-')}`,
        });
    }
    else {setError('Tienes que iniciar sesión para ver el perfil del médico.')
         setTimeout(() => {
           setError('');
         }, 5000);
        ;}
  };

  const onFilterSelected = (filterType, selectedData, isChecked) => {
    if (filterType === 'especialidad') {
      if (isChecked) {
        setSpecialitiesFilter((s) => [...s, selectedData.name]);
      } else {
        const index = specialitiesFilter.findIndex((s) => s === selectedData.id);
        index && setSpecialitiesFilter((current) => current.filter((s) => s !== selectedData.name));
      }
    } else {
      if (isChecked) {
        setCitiesFilter((s) => [...s, selectedData.name]);
      } else {
        const index = citiesFilter.findIndex((s) => s === selectedData.id);
        index && setCitiesFilter((current) => current.filter((s) => s !== selectedData.name));
      }
    }
  };

  useEffect(() => {
    const onFail = () => {
      setError('Error obteniendo información de busqueda');
      setDoctors([]);
    };

    const initialSearch = async () => {
      try {
        const { specialities: spcData, profiles } = await fetchDoctorsSearch(buildSearchParams(), onFail);
        profiles && setDoctors(profiles);
        spcData && setRelatedSpecialities(spcData);

        const citiesData = await getCity(setError);
        citiesData && setCities(citiesData);

        const specialitiesData = await getSpecialities(setError);
        specialitiesData && setSpecialities(specialitiesData);
      } catch (error) {
        console.error(error);
      }
    };

    initialSearch();
  }, [searchParams]);

  return {
    doctors: doctors.filter((doctor) => (citiesFilter.length === 0 ? true : citiesFilter.includes(doctor.city)) && (specialitiesFilter.length === 0 ? true : specialitiesFilter.includes(doctor.speciality))),
    relatedSpecialities,
    filters: [
      {
        id: 'especialidad',
        name: 'Especialidad',
        options: specialities
      },
      {
        id: 'ubicacion',
        name: 'Ubicación',
        options: cities
      }
    ],
    error,
    onFilterSelected,
    research,
    researchDoc,
    getCurrentSearchParams
  };
};

export default useSearchResults;
