import { authenticatedPatch, responseHandler, urlFormatter } from '../../../api/request';
export async function authenticatedPatchFile(url, payload = null) {
  const sessionToken = localStorage.getItem('BearerToken');
  if (!sessionToken) {
    console.error('No session found');
    return { status: 401, message: 'Not Authenticated' };
  }

  const authenticatedOptions = {
    method: 'PATCH',
    body: payload,
    headers: {
      Authorization: `Bearer ${sessionToken}`
    }
  };

  let response;
  try {
    response = await fetch(urlFormatter(url), authenticatedOptions);
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }

  return responseHandler(response);
}
export const updatePatientPersonalInfo = (userId, data, onFail) => {
  return authenticatedPatch(`/auth/${userId}`, onFail, data);
};
export const updatePatientPersonalInfoFile = (userId, data) => {
  return authenticatedPatchFile(`/auth/${userId}`, data);
};
