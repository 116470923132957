import axios from 'axios';
import { authenticatedFetch } from '../../api/request';

export default async function fetchDoctorsSearch(searchParams, onFail) {
  const API_BASE_URL = process.env.REACT_APP_API_SERVER;

   const response= await axios.get(`${API_BASE_URL}/search-doctors${searchParams}`);
   return response.data;
    // return await authenticatedFetch(`/search-doctors${searchParams}`, onFail);
}
