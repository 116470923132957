import { CheckCircleIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import configurations from '../../../common/utils/configurations';
import { format } from 'date-fns';
import { handleImageError } from '../../../common/helpers/helper';

const old = ({ person, researchDoc }) => (
  <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
    <div>
      <div className="w-full flex items-center justify-between py-3 px-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-dl-accent text-sm font-medium truncate">{person.fullName}</h3>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-dl-primary-900 text-xs font-medium bg-dl-primary-900/[.1] rounded-full">{person.speciality}</span>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">{person.city}</p>
        </div>
        <img className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0" src={person?.personalImage ? `${APP_SERVER}/${person?.personalImage}` : configurations?.defaultImage} alt="" />
      </div>
    </div>
    <div>
      <div className="-mt-px flex divide-x divide-gray-200">
        {/* <div className="w-0 flex-1 flex">
          <p className="py-2">
            <span className="ml-3 text-gray-500 text-xs block w-full">Fecha próxima:</span>
            <span className="ml-3 text-dl-accent text-sm block w-full">
              Lunes 16 de febrero 2022 <br></br> 07:00 am
            </span>
          </p>
        </div> */}
        <div className="-ml-px w-0 flex-1 flex">
          <Link
            to={`/doctor-results/${person.id}/${person.fullName.replace(' ', '-')}`}
            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-dl-accent font-medium border border-transparent rounded-br-lg hover:text-dl-accent/[.5]"
          >
            <div className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <CheckCircleIcon className="w-5 h-5 text-dl-accent" aria-hidden="true" />
              <span className="ml-3">Haz tu cita</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </li>
);

const APP_SERVER = process.env.REACT_APP_API_SERVER;
const ResultItem = ({ person, researchDoc }) => (
  <div className="filters-list-show-box-inner bg-white py-6 px-6 flex justify-between mb-4 border-l-8 rounded-l-lg rounded-r-full  border-light-blue  items-center ">
    <div className="filters-info flex items-center 	boxfilter-list-comm  flex-1">
      <figure className="border-4 rounded-full border-light-blue w-20 h-20">
        <span className="py-1 px-1 block w-100 h-full">
          <img src={person?.personalImage ? `${APP_SERVER}/${person?.personalImage}` : configurations?.defaultImage} className="h-full w-full rounded-full" alt="" onError={handleImageError} />
        </span>
      </figure>
      <figcaption className="ml-10 flex-1">
        <h4 className="text-black text-xl font-bold	">{person.fullName}</h4>
        <span className="tag-info light-blue py-2 px-3 rounded-full block text-center text-base	mt-3 w-44">{person.speciality}</span>
      </figcaption>
    </div>

    <div className="boxfilter-list-comm pr-12 pl-12 w-72 m-auto">
      <h4 className="text-black text-xl font-bold text-center">Ubicación</h4>
      <span className="tag-info tag-info light-blue py-2 px-3 rounded-full block text-center text-base mt-3 w-44 m-auto">{person.city}</span>
    </div>

    <div className="boxfilter-list-comm pr-12 pl-12 w-80 m-auto">
      <div className="availability-box light-blue py-8 px-6  rounded-[20px] text-center ">
        <h5 className="text-xl	font-bold 	pb-1">Disponibilidad</h5>
        { }
        <span className="availability-date text-xl font-normal">hasta {person?.latestAvailablityDate ? format(new Date(person.latestAvailablityDate), 'dd/MM/yyyy') : 'N/A'}</span>
      </div>
    </div>
    <div className="btn-box pr-6 pl-12">
      <Link to="#" onClick={() => researchDoc(person)}  className="bg-blue text-white py-3 px-6 rounded-full">
        Conocer más
      </Link>
    </div>
  </div>
);

export default ResultItem;
