import { format, parseISO, isWithinInterval } from 'date-fns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AppointmentItem(props) {
  const { appointment, setSelectedAppointment, isSelected, blockedSlots } = props;

  const isTimeBlocked = (date, appTime) => {
    const selectedTime = parseISO(`${date}T${appTime}`);

    for (const slot of blockedSlots) {
      const startTime = slot.date && slot.startTime ? parseISO(`${slot.date}T${slot.startTime}`) : '';
      const endTime = slot.date && slot.endTime ? parseISO(`${slot.date}T${slot.endTime}`) : '';

      if (startTime && endTime ? isWithinInterval(selectedTime, { start: startTime, end: endTime }) : false) {
        return true;
      }
    }

    return false;
  };


  let startDateTime = parseISO(`${appointment.date}T${appointment.appTime}`);

  const _onViewDetails = () => {
    setSelectedAppointment(appointment);
  };

  return (
    // <li onClick={_onViewDetails} className={classNames(isSelected ? 'active' : '', isTimeBlocked(appointment.date, appointment.appTime) ? 'cursor-not-allowed pointer-events-none border border-red-400' : 'border border-blue-400')}>
    //   {/* In case someday users have avatars */}
    //   {/*<img src={meeting.imageUrl} alt="" className="flex-none w-10 h-10 rounded-full" />*/}
    //   <div className="flex-auto">
    //     {/* <span>{appointment.userFullName}</span> */}
    //     <p className="mt-0.5">
    //       <time className={classNames(isTimeBlocked(appointment.date, appointment.appTime) ? 'text-red-500' : '')} dateTime={startDateTime}>
    //         {format(startDateTime, 'h:mm a')}
    //       </time>
    //     </p>
    //   </div>
    // </li>
    // <div className="time-slots">
    //     {timeSlots.map((time) => (
     isTimeBlocked(appointment.date, appointment.appTime)  ?  null : <button
            key={appointment.appTime}
            className={classNames(isSelected ? 'selected' : '', isTimeBlocked(appointment.date, appointment.appTime) ? 'time-slot cursor-not-allowed pointer-events-none border border-red-400' : 'time-slot border border-blue-400')}
            onClick={_onViewDetails}
          >
            {format(startDateTime, 'h:mm a')}
          </button>
      //   ))}
      // </div>
  );
}
