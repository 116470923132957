import React, { useState, useEffect } from "react";
import getAppointmentHistory from "../patientAppointmentManager/api/getAppointmentHistory";
import { Link } from "react-router-dom";
import { handleImageError } from '../common/helpers/helper';
const APP_SERVER = process.env.REACT_APP_API_SERVER;

const MyDoctors = () => {
  // Example state for doctors data
  const [doctors, setDoctors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  // Simulating API call
  useEffect(() => {
    // Replace this with an actual API call
    const fetchDoctors = async () => {
        const data = await getAppointmentHistory(setErrorMessage, 2);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
        setDoctors(data);
    }
    fetchDoctors();
  }, []);

  return (
    <div className="wrapper-box doctor-list mis-doctores-list">
        <div className="mis-doctores-header mt-5">
            <h2 className="common-title pb-5">Mis doctores</h2>
        </div>
        <div className="grid grid-cols-4 gap-8 mb-10 max-h-[500px] overflow-y-auto">
            {doctors.length ? 
            doctors?.map((appointment, index) => (
                <Link to={`/doctor-results/${appointment.idDoctorUser}/${appointment.doctorFullName.replace(' ', '-')}`} key={appointment?.idDoctorUser || index}>
                <div className="doctor-card">
                    <img
                        src={APP_SERVER + appointment?.doctor_image}
                        alt="Doctor Avatar"
                        className="avatar"
                        onError={handleImageError}
                    />
                    <div className="info">
                        <span className="specialty">Dr. {appointment?.doctorFullName}</span>
                        <span className="specialty">{appointment?.speciality}</span>
                    </div>
                </div>
                </Link>
            ))
            : null}
        </div>
    </div>
  );
};

export default MyDoctors;
