import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { InformationCircleIcon, XIcon, ZoomOutIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, FilterIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import useSearchResults from './hooks/useSearchResults';
import ResultItem from './components/resultItem/ResultItem';
import profildummy from '../images/profile-dummy.png';
import Search from '../home/components/Search';
import { buildSearch } from '../common/utils/url';
import { Link, useNavigate } from 'react-router-dom';

// const subCategories = [
//   { name: 'Totes', href: '#' },
//   { name: 'Backpacks', href: '#' },
//   { name: 'Travel Bags', href: '#' },
//   { name: 'Hip Bags', href: '#' },
//   { name: 'Laptop Sleeves', href: '#' }
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FilterElement() {
  const navigate = useNavigate();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { doctors, filters, error, research, relatedSpecialities: specialities, onFilterSelected, researchDoc } = useSearchResults();

  return (
    <div className="bg-dl-screen-lightblue py-12 lg:px-8 mx-auto px-4 relative sm:px-6">
      <div className="bg-dl-screen-lightblue  min-h-[calc(100vh_-_306px)]">
        <div>
          {error !== '' && (
            <div className="mb-8 bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md" role="alert">
              <div className="flex">
                <div className="py-1">
                  <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold mb-4">{error}</p>
                  <Link to="/sign-in" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Inicia sesión
                  </Link>
                </div>
              </div>
            </div>
          )}
          {/* Mobile filter dialog */}
          {/* <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                  <div className="px-4 flex items-center justify-between">
                    <h2 className="text-lg font-medium text-dl-accent">Filtros</h2>
                    <button type="button" className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-dl-accent" onClick={() => setMobileFiltersOpen(false)}>
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  // Filters 
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    <ul role="list" className="font-medium text-dl-accent px-2 py-3">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {filters.map((section) => (
                      <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-dl-accent">{section.name}</span>
                                <span className="ml-6 flex items-center">{open ? <MinusSmIcon className="h-5 w-5" aria-hidden="true" /> : <PlusSmIcon className="h-5 w-5" aria-hidden="true" />}</span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={section.id}
                                      defaultValue={option.name}
                                      type="checkbox"
                                      className="h-4 w-4 border-gray-300 rounded text-dl-primary-900 focus:ring-dl-primary-900"
                                      onClick={(e) => {
                                        onFilterSelected(section.id, option, e.target.checked);
                                      }}
                                    />
                                    <label htmlFor={`filter-mobile-${section.id}-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-dl-accent">
                                      {option.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </div>
              </Transition.Child>
            </Dialog>
          </Transition.Root> */}

          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative z-10 flex items-baseline justify-center pt-12 pb-6  border-gray-200">
              <h1 className="text-4xl font-extrabold tracking-tight text-dl-secondary">Los especialistas médicos reunidos en una sola plataforma</h1>

              {/* <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a href={option.href} className={classNames(option.current ? 'font-medium text-dl-accent' : 'text-dl-accent/[.5]', active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}>
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <button type="button" className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden" onClick={() => setMobileFiltersOpen(true)}>
                  <span className="sr-only">Filters</span>
                  <FilterIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div> */}
            </div>

            <Search />

            <section aria-labelledby="products-heading" className="pb-24">
              {/* <h2 id="products-heading" className="sr-only">
                Products
              </h2> */}

              {/* <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                // Filters
                <form className="hidden lg:block bg-white p-5 rounded-sm">
                  <h3 className="sr-only">Categories</h3>
                  <div role="list" className="text-sm font-medium text-dl-accent space-y-4 pb-6 border-b border-gray-200">
                    {specialities.map((category) => (
                      <div key={category.name} role="button" onClick={() => research({ q: category.name })}>
                        <span>{category.name}</span>
                      </div>
                    ))}
                  </div>

                  {filters.map((section) => (
                    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-dl-accent">{section.name}</span>
                              <span className="ml-6 flex items-center">{open ? <MinusSmIcon className="h-5 w-5" aria-hidden="true" /> : <PlusSmIcon className="h-5 w-5" aria-hidden="true" />}</span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.name} className="flex items-center">
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}`}
                                    defaultValue={option.name}
                                    type="checkbox"
                                    className="h-4 w-4 border-gray-300 rounded text-dl-primary-900 focus:ring-dl-primary-900"
                                    onClick={(e) => {
                                      onFilterSelected(section.id, option, e.target.checked);
                                    }}
                                  />
                                  <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-dl-accent">
                                    {option.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>

                Product grid/
                <div className="lg:col-span-3 bg-dl-screen-lightblue">
                //   Replace with your content
                //   /End replace
                </div>
              </div> */}

              <div className="filters-box">
                <ul className="filters-box-list flex items-center">
                  {specialities.map((category) => (
                    <li className="bg-blue text-white py-2 px-4 rounded-full text-base mr-5 w-44 text-center" key={category.name} role="button" onClick={() => research({ q: category.name })}>
                      <button onClick={() => navigate({ pathname: '/search-results', search: buildSearch({ q: category.name }) })}>{category.name}</button>
                    </li>
                  ))}
                  {/* <li className="bg-blue text-white py-2 px-4 rounded-full text-base mr-5 w-44 text-center" >
                    <button>Oftalmología</button>
                  </li>
                  <li className="light-blue text-light py-2 px-4 rounded-full text-base mr-5 w-44 text-center">
                    <button>Urología</button>
                  </li> */}
                </ul>

                <hr className=" border-0 border-b-4  border-blue rounded-full "></hr>
              </div>

              <div className="filters-list-show-box mt-12">
                {doctors.length === 0 ? (
                  <div className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                      <div className="py-1">
                        <ZoomOutIcon className=" h-6 w-6 text-blue-500 mr-4" />
                      </div>
                      <div>
                        <p className="font-bold">No se encontraron resultados.</p>
                        <p className="text-sm">Intente con otros parámetros.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                    doctors.map((doctor) => <ResultItem key={doctor.id} person={doctor} researchDoc={researchDoc} />)
                )}
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}
