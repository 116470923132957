import Countrydata from './Countrydata.json';

export const formattedCountryData = Countrydata.map((country) => ({
  id: country.code,
  label: country.name_es,
  value: country.name_es
}));

export const formattedGenderData = [
  { id: 'male', label: 'masculino', value: 'masculino' },
  { id: 'female', label: 'femenina', value: 'femenina' },
  { id: 'other', label: 'otra', value: 'otra' }
];
export const formattedBloodData = [
  { id: 'A+', label: 'A+', value: 'A+' },
  { id: 'A', label: 'A', value: 'A' },
  { id: 'B+', label: 'B+', value: 'B+' },
  { id: 'O+', label: 'O+', value: 'O+' },
  { id: 'O-', label: 'O-', value: 'O-' },
  { id: 'AB+', label: 'AB+', value: 'AB+' },
  { id: 'AB-', label: 'AB-', value: 'AB-' },
  { id: 'B+', label: 'B+', value: 'B+' }
];

// Every new personal document should be added here to get the actual spanish label.
export const personalDocuments = {
  dui: 'DUI',
  passport: 'Pasaporte'
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const formatDate = (dateStr, format) => {
  const [year, month, day] = dateStr.split('-');

  const date = new Date(year, month - 1, day);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const formattedMonth = monthNames[date.getMonth()];
  let formattedDate;
  if (format) {
    formattedDate = `${year}-${month}-${day}`;
  } else {
    formattedDate = `${day} ${formattedMonth} ${year}`;
  }

  return formattedDate;
};
