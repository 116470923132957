import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formattedCountryData, personalDocuments } from '../../../common/utils/formattedData';
import { updateDoctorPersonalInfo as updateDoctorInfo } from '../api/updateDoctorPersonalInfo';
import { updatePatientPersonalInfo as updatePatientInfo, updatePatientPersonalInfoFile } from '../api/updatePatientPersonalInfo';
import defaultImage from '../../../images/profile.png';

const useInitialForm = (userType, userProfile, userId) => {
  const APP_SERVER = process.env.REACT_APP_API_SERVER;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(userProfile.personalImage ? `${APP_SERVER}/${userProfile.personalImage}` : defaultImage);
  const nationality = formattedCountryData.find((nationality) => (nationality.label = userProfile?.nationality));
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger
  } = useForm({
    defaultValues: {
      ...userProfile,
      nationality,
      birthDate: new Date(userProfile?.birthDate).toLocaleDateString(),
      picture: ''
    }
  });

  const saveProfileForm = async ({ phone, picture, personalImage }) => {
    setIsLoading(true);

    const onFail = () => {
      setShowErrorModal(true);
    };
    let formData = new FormData();
    formData.append('phone', phone);
    formData.append('personalImage', personalImage);

    const data = userType === 'doctor' ? await updateDoctorInfo(userId, { phone, picture }, onFail) : await updatePatientPersonalInfoFile(userId, formData);

    setIsLoading(false);

    if (data) {
      setShowSuccessModal(true);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValue('personalImage', file);
    trigger('personalImage', { shouldValidate: true });
    setPreviewImage(URL.createObjectURL(file));
  };
  const onCloseErrorMessage = () => {
    setShowErrorModal(false);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
  };

  return {
    formattedCountryData,
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    saveProfileForm,
    control,
    personalDocuments,
    showErrorModal,
    isLoading,
    showSuccessModal,
    onCloseErrorMessage,
    onCloseSuccessMessage,
    handleImageChange,
    previewImage
  };
};

export default useInitialForm;
